module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tubex-tube","short_name":"tubex-tube","start_url":"/","background_color":"#64CCC9","theme_color":"#64CCC9","icon":"C:\\Users\\Lambdaxyzt\\Downloads\\New folder\\Project\\Raya ICT\\tubex-tube\\src\\data\\images\\favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2b10782cdd8db06c5bfd2647ee70c908"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
